.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 170px 0;
    font-family: Dosis;
}

.intro-image {
    width: 670px;
    height: 445px;
}

.body-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 907px;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
}

p.header {
    font-weight: 600;
    margin: 0;
    padding: 18px;
}

p.text {
    margin-top: 0;
    margin-bottom: 32px;
}

.info-text {
    color: #FF0000;
    margin-bottom: 24px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 191px;
    height: 64px;
    background: #3DB7E4;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 32px;
}

.privacy-policy-wrapper {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

a {
    color: black;
}

/*
    tablet
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .body {
        margin: 0 64px;
    }
    .intro-image {
        width: 579px;
        height: 388px;
    }
    .body-text {
        font-size: 18px;
        line-height: 28px;
        width: auto;
    }
    .button {
        width: 184px;
        margin-bottom: 52px;
    }
}

/*
    mobile
*/

@media screen and (max-width: 767px) {
    .body {
        margin: 0 32px;
    }
    .intro-image {
        width: 311px;
        height: 208px;
    }
    .body-text {
        font-size: 18px;
        line-height: 28px;
        width: auto;
    }
    .button {
        width: 311px;
    }
}