.breakpoint {
    width: 100%;
}

.question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
}

.question {
    font-family: Dosis;
    font-size: 56px;
    line-height: 76px;
    text-align: center;
    margin-top: 80px;
    height: 430px;
    overflow: auto;
    cursor: default;
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
}

/*
    tablet
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .question {
        margin-top: 48px;
        font-size: 52px;
        line-height: 68px;
        height: 523px;
        margin-bottom: 24px;
    }
}

/*
    mobile
*/

@media (max-width: 767px) {
    .question {
        margin-top: 34px;
        font-size: 24px;
        line-height: 36px;
        height: 200px;
        margin-bottom: 37px;
    }
}