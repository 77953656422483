.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 64px;
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    background: #3DB7E4;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 32px;
}

.subheader {
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .back-button {
        width: 311px;
    }
}