.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 141px;
    padding-bottom: 24px;
}

.slider-value {
    font-family: Dosis;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: #101010;
    margin-bottom: 5px;
}

.slider {
    -webkit-appearance: none;
    outline: none;
    width: 100%;
    height: 32px;
    margin-bottom: 25px;
    border-radius: 16px;
    background: -webkit-gradient(linear, left top, right top, from(rgb(239, 241, 241)), to(rgb(200, 202, 202)));
    background: linear-gradient(90deg, rgb(239, 241, 241) 0%, rgb(200, 202, 202) 100%);
    -webkit-transition: 0.2s;
    -webkit-transition: opacity 0.2s;
    transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 80px;
    height: 80px;
    background-image: url(../assets/images/sliderCircle.png);
    background-repeat: no-repeat;
    position: relative;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-repeat: no-repeat;
    border: none;
    background-image: url(../assets/images/sliderCircle.png);
    cursor: pointer;
}

.slider::-moz-focus-outer {
    border: 0;
}

datalist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -45px;
}

option {
    background: url(../assets/images/oval.png) no-repeat;
    height: 8px;
    width: 8px;
}

/*
    tablet
*/

@media (min-width: 768px) and (max-width: 1024px) {
    option.end-point-left {
        margin-left: 34px;
    }
    option.end-point-right {
        margin-right: 30px;
    }
}

/*
    mobile
*/

@media (max-width: 767px) {
    .slider-value {
        font-size: 24px;
    }
    option.end-point-left {
        margin-left: 30px;
    }
    option.end-point-right {
        margin-right: 30px;
    }
    .slider::-webkit-slider-thumb {
        width: 64px;
        height: 64px;
        background: url(../assets/images/sliderCircleSmall.png)
    }
    .slider::-moz-range-thumb {
        width: 64px;
        height: 64px;
        background: url(../assets/images/sliderCircleSmall.png)
    }
}