.progressbar-wrapper {
    width: 100%;
}

.progressbar-text-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    height: 45px;
}

.progressbar-text {
    font-family: Dosis;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 16px;
    color: #6C6F70;
}

progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    width: 100%;
    height: 3px;
    padding: 0 32px;
}

progress[value]::-webkit-progress-bar {
    display: flex;
    align-items: center;
    background: -webkit-gradient(linear, left top, right top, from(rgb(239, 241, 241)), to(rgb(200, 202, 202)));
    background: linear-gradient(90deg, rgb(239, 241, 241) 0%, rgb(200, 202, 202) 100%);
    /* opacity: 0.2; */
    border-radius: 6px;
}

progress[value]::-moz-progress-bar {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgb(239, 241, 241) 0%, rgb(200, 202, 202) 100%);
    /* opacity: 0.2; */
    border-radius: 6px;
}

progress[value]::-ms-fill {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgb(239, 241, 241) 0%, rgb(200, 202, 202) 100%);
    /* opacity: 0.2; */
    border-radius: 6px;
    border: none;
}

progress[value]::-webkit-progress-value {
    background: #6C6F70;
    border-radius: 4px;
    height: 4px;
}

@-webkit-keyframes animate-stripes {
    100% {
        background-position: -100px 0px;
    }
}

@keyframes animate-stripes {
    100% {
        background-position: -100px 0px;
    }
}