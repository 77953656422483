.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 35px;
    font-family: Dosis;
}

.close-button {
    display: flex;
    justify-content: center;
    height: 48px;
    width: 48px;
    border: 1px solid #D8D8D8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: white;
    padding: 0;
}

.close-icon {
    background: url('../assets/images/closeIcon.png') no-repeat;
    width: 30px;
    height: 30px;
}

/*
    mobile version
*/

@media screen and (max-width: 575px) {
    .info.desktop {
        display: none;
    }
    .info.mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 48px;
        border: 1px solid #D8D8D8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        text-decoration: none;
        color: #C6C6C6;;
        font-weight: 400;
        font-size: 32px;
    }
}

/*
    desktop version
*/

@media screen and (min-width: 576px) {
    .info.mobile {
        display: none;
    }
    .info.desktop {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 76px;
        border: 1px solid #D8D8D8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        text-decoration: none;
        color: #C6C6C6;;
        font-weight: bold;
        font-size: 32px;
    }  
}