.footer-wrapper {
    display: flex;
    flex-direction: row;
    padding: 32px;
    justify-content: flex-end;
}

.footer-next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: #3DB7E4;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: none;
    outline: none;
}

.inner-next-button {
    width: 17px;
    height: 30px;
    background: url('../assets/images/innerNextBtn.png') no-repeat;
}

.inner-back-button {
    width: 17px;
    height: 30px;
    background: url('../assets/images/innerBackBtn.png') no-repeat;
}