.matching-types-wrapper {
    width: 100%;
    height: 93px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.matching-types {
    display: flex;
    overflow: auto;
}

.matching-types input[type="radio"] {
    display: none;
}

.matching-types label {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 225px;
    height: 85px;
    max-height: 89px;
    color: white;
    padding: 0 8px;
    text-align: center;
    font-size: 24px;
    font-family: Dosis;
    font-weight: 600;
    -ms-user-select: None;
    -moz-user-select: None;
    -webkit-user-select: None;
}

label[for="-3"] {
    background-color: #3DB7E4;
    border: 4px solid #3DB7E4;
    margin-right: 16px;
}

label[for="-2"] {
    background-color: #57BEC9;
    border: 4px solid #57BEC9;
    margin-right: 16px;
}

label[for="-1"] {
    background-color: #70C6AE;
    border: 4px solid #70C6AE;
    text-align: center;
    margin-right: 16px;
}

label[for="0"] {
    background-color: #8ACD93;
    border: 4px solid #8ACD93;
    margin-right: 16px;
}

label[for="1"] {
    background-color: #A3D478;
    border: 4px solid #A3D478;
    margin-right: 16px;
}

label[for="2"] {
    background-color: #BCDC5D;
    border: 4px solid #BCDC5D;
    margin-right: 16px;
}

label[for="3"] {
    background-color: #D6E342;
    border: 4px solid #D6E342;
}

.matching-types input[type="radio"]:hover+label {
    background-color: white;
}

.matching-types input[type="radio"]:hover+label[for="-3"] {
    color: #3DB7E4;
}

.matching-types input[type="radio"]:hover+label[for="-2"] {
    color: #57BEC9;
}

.matching-types input[type="radio"]:hover+label[for="-1"] {
    color: #70C6AE;
}

.matching-types input[type="radio"]:hover+label[for="0"] {
    color: #8ACD93;
}

.matching-types input[type="radio"]:hover+label[for="1"] {
    color: #A3D478;
}

.matching-types input[type="radio"]:hover+label[for="2"] {
    color: #BCDC5D;
}

.matching-types input[type="radio"]:hover+label[for="3"] {
    color: #D6E342;
}