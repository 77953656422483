.result-image {
    width: 670px;
    height: 447px;
}

.social-media {
    display: flex;
    flex-direction: row;
}

.linkedin {
    background: url('../assets/images/linkedinDesktop.png') no-repeat;
    width: 75px;
    height: 64px;
    margin: 0 16px 32px;
}

.instagram {
    background: url('../assets/images/instagramDesktop.png') no-repeat;
    width: 64px;
    height: 64px;
    margin: 0 16px 32px;
}

.desire-wrapper {
    margin-bottom: 20px; 
    font-size: 22px;
}

.desire {
    margin: 12px;
    font-weight: 600;
    font-size: 22px;
}

/*
    tablet
*/

@media (min-width: 768px) and (max-width: 1024px)  {
    .result-image {
        width: 580px;
        height: 387px;
    }
    .linkedin {
        background: url('../assets/images/linkedinMobile.png') no-repeat;
        width: 56px;
        height: 48px;
    }
    .instagram {
        background: url('../assets/images/instagramMobile.png') no-repeat;
        width: 48px;
        height: 48px;
    }
}

/*
    mobile
*/

@media screen and (max-width: 767px) {
    .result-image {
        width: 311px;
        height: 208px;
    }
    .linkedin {
        background: url('../assets/images/linkedinMobile.png') no-repeat;
        width: 56px;
        height: 48px;
    }
    .instagram {
        background: url('../assets/images/instagramMobile.png') no-repeat;
        width: 48px;
        height: 48px;
    }
}